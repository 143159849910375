require("slick-carousel/slick/slick")

$(() => {
    $(".slider").not('.slick-initialized').slick();

    $(".image-slider").not('.slick-initialized').slick({
        // Default settings:
        // Small (mobile) view
        // Adaptive height

        autoplay: false,
        adaptiveHeight: true,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 500,
                settings: {
                    adaptiveHeight: false,
                    autoplay: true
                }
            }]
    });
});